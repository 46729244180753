
import Spinner from "@/components/shared/Spinner.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: { Spinner }
})
export default class CourseRevenueStatistics extends Vue {
  @Prop({ required: true, default: () => [] })
  items!: Array<{
    courseId: number;
    courseName: string;
    coursePrice: number;
    eventCount: number;
    eventDelegateCount: number;
    monthRevenue: number;
  }>;
  @Prop(Boolean) loading!: boolean;

  @Prop({ required: true, default: () => [] })
  chartData!: Array<{
    label: string;
    value: number;
  }>;

  @Prop({ default: "Course Revenue Statistics" })
  title!: string;

  get series() {
    return [
      {
        name: "Revenue",
        data: this.chartData.map((item: { label: string; value: number }) =>
          item.value.toFixed(2)
        )
      }
    ];
  }
  get chartOptions() {
    return {
      chart: {
        type: "line",
        toolbar: {
          show: false
        }
      },
      legend: {
        show: false
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7"
      ],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        categories: this.chartData.map(
          (item: { label: string; value: number }) => item.label
        )
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      }
    };
  }
  get dataSource() {
    if (this.items) {
      return this.items.map((item: any) => ({ value: item.eventCount }));
    }
    return [];
  }
  formatMoney(value: number | string) {
    const num = parseFloat(value.toString());
    const formattedMoney = new Intl.NumberFormat("en", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2
    }).format(num);
    return formattedMoney.substr(2);
  }
}
