
import Spinner from "@/components/shared/Spinner.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: { Spinner }
})
export default class MostPopularCourseStatistics extends Vue {
  @Prop({ required: true, default: () => [] })
  items!: Array<{
    courseId: number;
    courseName: string;
    eventCount: number;
    eventDelegateCount: number;
    instructorsName: string;
  }>;
  @Prop(Boolean) loading!: boolean;
  @Prop({ default: "Most Popular Course Statistics" })
  title!: string;

  get series() {
    return [
      {
        data: this.items.map((item: any) => item.eventCount)
      }
    ];
  }
  get chartOptions() {
    return {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        }
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom"
          }
        }
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7"
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"]
        },
        formatter: function(val: string, opt: any) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 10,
        dropShadow: {
          enabled: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: this.items.map((item: any) => item.courseName)
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function() {
              return "";
            }
          }
        }
      }
    };
  }
}
