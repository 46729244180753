
import Spinner from "@/components/shared/Spinner.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: { Spinner }
})
export default class MostPopularCourseStatistics extends Vue {
  @Prop()
  data!: {
    countCompanies: number;
    countDelegates: number;
    countEvents: number;
  };
}
