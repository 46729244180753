
import Spinner from "@/components/shared/Spinner.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: { Spinner }
})
export default class StatisticByInstructorPermanentOutsource extends Vue {
  @Prop()
  data!: {
    outsourceInstructorStaffMembers: number;
    outsourceInstructorsAssigned: number;
    permanentInstructorStaffMembers: number;
    permanentInstructorsAssigned: number;
    outsourceInstructorsAssignedPercent: number;
    permanentInstructorsAssignedPercent: number;
  };

  get series() {
    return [
      +this.data.outsourceInstructorsAssignedPercent.toFixed(2),
      +this.data.permanentInstructorsAssignedPercent.toFixed(2)
    ];
  }
  get chartOptions() {
    return {
      chart: {
        type: "pie",
        toolbar: {
          show: false
        }
      },
      labels: ["Outsource staff amount", "Permanent staff amount"],
      legend: {
        show: true,
        position: "bottom"
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7"
      ]
    };
  }
}
