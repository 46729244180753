
import { Statistics } from "@/api";
import { StatisticInstructor } from "@/api/types";
import Spinner from "@/components/shared/Spinner.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: { Spinner }
})
export default class DailyStatisticByInstructor extends Vue {
  instructors: StatisticInstructor[] = [];
  loading = false;
  async created() {
    this.loading = true;
    try {
      this.instructors = await Statistics.getInstructorsDailyStatistics();
    } catch (e) {
      this.$dialog.notify.error(
        "Error loading Daily statistic by Instructors",
        {
          position: "bottom-right",
          timeout: 5000
        }
      );
    } finally {
      this.loading = false;
    }
  }
}
