
import Spinner from "@/components/shared/Spinner.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: { Spinner }
})
export default class CCAgentsCourseStatistics extends Vue {
  @Prop({ required: true, default: () => [] })
  items!: Array<{
    name: string;
    countCreateEvents: number;
    countEditEvents: number;
  }>;

  get series() {
    return [
      {
        name: "Booked Course",
        data: this.items.map(
          ccAgentCreatedCourses => ccAgentCreatedCourses.countCreateEvents
        )
      },
      {
        name: "Modified Course",
        data: this.items.map(
          ccAgentCreatedCourses => ccAgentCreatedCourses.countEditEvents
        )
      }
    ];
  }
  get chartOptions() {
    return {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false
        }
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "bottom"
          }
        }
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7"
      ],
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        style: {
          colors: ["#fff"]
        },
        dropShadow: {
          enabled: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        type: "string",
        categories: this.items.map(
          ccAgentCreatedCourses => ccAgentCreatedCourses.name
        )
      },
      yaxis: {
        labels: {
          show: true
        }
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false
        },
        y: {
          show: false,
          title: {
            formatter: function(val: string) {
              return `${val}`;
            }
          }
        }
      }
    };
  }
}
