
import { Statistics } from "@/api";
import { BoardFilter, StatisticBoard, StatisticCCAgent } from "@/api/types";
import TitleHeader from "@/components/common/TitleHeader.vue";
import CCAgentsCourseStatistics from "@/components/reports/CCAgentsCourseStatistics.vue";
import CourseRevenueStatistics from "@/components/reports/CourseRevenueStatistics.vue";
import DailyStatisticByInstructor from "@/components/reports/DailyStatisticByInstructor.vue";
import MostPopularCourseStatistics from "@/components/reports/MostPopularCourseStatistics.vue";
import StatisticByBookingTrainingCourse from "@/components/reports/StatisticByBookingTrainingCourse.vue";
import StatisticByInstructorPermanentOutsource from "@/components/reports/StatisticByInstructorPermanentOutsource.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    StatisticByInstructorPermanentOutsource,
    StatisticByBookingTrainingCourse,
    CourseRevenueStatistics,
    MostPopularCourseStatistics,
    DailyStatisticByInstructor,
    TitleHeader,
    CCAgentsCourseStatistics
  }
})
export default class ReportsPage extends Vue {
  headers = [{ icon: "mdi-signal-cellular-3", header: "Reports Board" }];
  stat: StatisticBoard = {};
  filters: Array<BoardFilter> = [];
  activeFilter: number | null = null;
  ccAgents: Array<StatisticCCAgent> = [];
  loading = false;

  get mostPopularCoursesStatistic() {
    return this.stat.mostPopularCoursesStatistic;
  }
  get mostPopularOnlineCoursesStatistic() {
    return this.stat.mostPopularOnlineCoursesStatistic;
  }
  get courseRevenueStatistics() {
    return this.stat.courseRevenueStatistic;
  }
  get onlineCourseRevenueStatistics() {
    return this.stat.onlineCourseRevenueStatistic;
  }
  get courseRevenueStatisticChart() {
    return this.stat.courseRevenueStatisticChart;
  }
  get onlineCourseRevenueStatisticChart() {
    return this.stat.onlineCourseRevenueStatisticChart;
  }
  get statisticsByBookingTrainingCourses() {
    return this.stat.statisticsByBookingTrainingCourses;
  }
  get instructorsServiceStatistics() {
    return this.stat.instructorsServiceStatistics;
  }

  @Watch("activeFilter")
  async onFilterChange() {
    this.loading = true;
    this.stat = await Statistics.getAllStat(this.activeFilter);
    this.ccAgents = await Statistics.getStatsCCAgent(this.activeFilter);
    this.loading = false;
  }
  async created() {
    this.loading = true;
    try {
      this.filters = await Statistics.getBoardFilters();
      const activeFilter = this.filters.find(
        (filter: BoardFilter) => filter.active
      );
      if (activeFilter) {
        this.activeFilter = activeFilter.key;
      }

      // this.stat = await Statistics.getAllStat(this.activeFilter);
      // this.ccAgents = await Statistics.getStatsCCAgent(this.activeFilter);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
    this.loading = false;
  }
}
